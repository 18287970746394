.m-show,
.portrait-show {
  display: none !important;
}
.m-hide {
  display: block !important;
}
.hide {
  display: none;
}
.d-flex {
  display: flex;
  flex-wrap: wrap;
}
html,
body {
  overflow-x: hidden;
}
.emptyStatus {
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25)) !important;
  border-radius: 15px;
}
#muteTag {
  background: rgba(91, 139, 33, 0.8);
  width: auto;
  display: inline-flex;
  position: absolute;
  top: 15px;
  z-index: 9;
  right: 15px;
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
  text-align: center;
}
.common-tabs {
  padding: 0 10px;
  .react-tabs__tab-list {
    display: flex;
  }
  .react-tabs__tab {
    padding: 10px 0;
    margin-right: 50px;
    border-bottom: 2px solid transparent;
    &.react-tabs__tab--selected {
      border-bottom: 2px solid;
      @include theme(border-color, themeColor);
    }
    @media screen and (max-width: 991px) {
      font-size: 14px;
      font-weight: 700;
    }
  }
  .common-tab-panel {
    padding: 18px 8px;
    background: #f9f9f9;
  }
  .cta__detailwrap {
    height: 292px;
  }
  .chat-message h4 {
    color: #000 !important;
  }
  .cta__card {
    width: 100%;
    padding-left: 10px;
    min-height: 76px;
  }
  .cta__card .btn {
    padding-left: 0;
  }
  .m-btn-minimize {
    display: none;
  }
  .live__chat {
    width: 100%;
    height: auto;
    max-width: none;
    position: relative;
    left: auto;
    right: auto;
    background: transparent;
  }
  .live__chat__header {
    display: none;
  }
  .live__chat__body {
    height: 250px;
  }
  .live__chat__footer {
    background: #777777;
    border-radius: 10px;
    height: 42px;
    display: flex;
    align-items: center;
  }
}

.planned_timer_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  img {
    width: 50%;
  }
}
.planned_timer {
  z-index: 1;
  width: 38%;
  height: 500px;
  background: #d81c5b;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 50px;
  text-align: center;
  @media screen and (max-width: 1300px) {
    font-size: 35px;
    padding: 10px;
  }
}
img {
  max-width: 100%;
}

.showaction {
  position: relative;
  width: 80%;
  margin: 0px auto;
  span {
    font-size: 16px;
    line-height: 21px;
    @include theme(color, themeColor);
    width: 150px;
    display: flex;
    margin: auto;
    justify-content: center;
    background: #fff;
    position: relative;
    align-items: center;
    cursor: pointer;
    font-weight: 700;
    text-transform: capitalize;
    img,
    svg {
      margin-left: 8px;
      margin-top: 3px;
      transition: transform 0.3s ease-in-out;
      path {
        @include theme(fill, themeColor);
      }
    }
    &:hover {
      img,
      svg {
        animation-name: bounceAlpha;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }
  }
  &::before {
    content: "";
    height: 1px;
    width: 100%;
    background: #707070;
    position: absolute;
    top: 13px;
    left: 0;
  }
  &.showless {
    span {
      img,
      svg {
        transform: translateY(-2px) rotate(180deg);
      }
      &:hover {
        img,
        svg {
          animation-name: bounceAlphaReverse;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }
    }
  }
  &.showmore {
    img {
      transform: translateY(0px) rotate(0deg);
    }
  }
  @media screen and (max-width: 520px) {
    width: 90%;
    margin: 10px auto 0;
    span {
      font-size: 14px;
      img {
        width: 10px;
      }
    }
  }
}
.pre-recorded-live {
  .vjs-progress-control,
  .vjs-remaining-time {
    display: none !important;
  }
  .vjs-live-control {
    display: flex !important;
  }
}
@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateY(10px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateY(-10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}
@keyframes bounceAlphaReverse {
  0% {
    opacity: 1;
    transform: translateY(0px) rotate(180deg) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateY(10px) rotate(180deg) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateY(-10px) rotate(180deg) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateY(0px) rotate(180deg) scale(1);
  }
}
@media screen and (max-width: 1024px) {
  .fullscreen-video {
    .active {
      display: block !important;
    }
    .inactive {
      display: none !important;
    }
  }
}

@media screen and (max-width: 991px) {
  .m-show {
    display: block !important;
  }
  .m-hide {
    display: none !important;
  }
  .portrait-show {
    display: block !important;
    .live__chat__header {
      &.pin-chat {
        background: transparent !important;
        h5 {
          text-align: left;
          color: #000;
        }
      }
      svg {
        display: none !important;
      }
    }
  }
  .vjs-poster {
    background-size: cover !important;
  }
  .btn-chat,
  .btn-cart {
    display: block !important;
  }
  .fullscreen-mode {
    .portrait-show {
      display: none !important;
    }
    .fullscreen-video {
      .live__chat__body .chat-message.sent {
        text-align: left;
      }
    }
    .btn-chat,
    .btn-cart {
      display: block !important;
    }
  }
  .planned_timer {
    height: 100px;
    font-size: 18px;
    padding: 10px;
  }
  .video-js .vjs-remaining-time.vjs-time-control {
    display: inline-flex !important;
    justify-content: flex-end;
    height: 20px;
    bottom: -5px;
    .vjs-remaining-time-display,
    span {
      line-height: 15px;
    }
  }
  .video-js.vjs-live .vjs-remaining-time.vjs-time-control {
    display: none !important;
  }
  .emptyStatus {
    width: 200px;
  }
  .videoplayer__controllbar button:last-of-type.btn-expand {
    width: 20px !important;
  }

  @media screen and (orientation: portrait) {
    .landspace_fullscreen {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      height: 100vw;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
      .fullscreen-video.template__wrapper {
        height: 100vw;
        width: 100vh;
      }
    }
    .videoplayer__controllbar button:last-of-type {
      &.btn-expand {
        width: 20px !important;
      }
    }

    // .fullscreen-video {
    .cta__wrapper {
      height: 200px !important;
      width: 100% !important;
      min-width: 100% !important;
      padding-top: 35px !important;
      display: block;
      position: absolute !important;
      bottom: 60px;
      background: rgba(85, 85, 85, 0.8);
      z-index: 9;
    }
    .cta__wrapper .m-btn-minimize {
      left: 0;
      right: 0;
      top: 9px;
      margin: auto;
      z-index: 99;
      width: 100%;
      text-align: center;
      img {
        margin: auto;
      }
    }
    .portraitInactive {
      height: 0px !important;
      padding: 0px !important;
    }
    .live__chat {
      background: rgba(85, 85, 85, 0.8) !important;
      overflow: visible;
      .live__chat__header.pin-chat h5 {
        display: block;
        font-size: 12px;
        text-align: center;
        position: absolute;
        top: auto;
        bottom: 180px;
        padding: 10px 15px;
        z-index: 1;
        background: #262220;
        width: 100%;
      }
    }
    .live__chat.nonactive {
      visibility: hidden;
      opacity: 0;
      height: 0;
    }
    // .live__chat.active.is_minimized{
    //   height: 40vh;
    //   visibility: visible;
    //   opacity: 1;
    // }
    .live__chat__header {
      display: flex !important;
      padding-top: 10px;
      align-items: center;
      justify-content: center;
      border-color: transparent !important;
    }
    .live__chat__footer button {
      top: 10px !important;
      right: 15px !important;
    }
    .live__chat.isclose {
      top: auto !important;
      opacity: 0 !important;
    }
    .cta {
      &__wrapper {
        &.portraitInactive {
          opacity: 0;
        }
        &.portraitActive {
          opacity: 1;
          &.cta__close {
            height: 0 !important;
            opacity: 0 !important;
          }
        }
        &.cta__close {
          .m-btn-minimize {
            right: auto !important;
          }
        }
      }
    }
    .fullscreen-video {
      .cta__wrapper {
        height: 45vh !important;
      }
      .live__chat__header.pin-chat h5 {
        bottom: 40vh;
      }
    }
  }
  @media screen and (orientation: landscape) {
    .btn-chat,
    .btn-cart {
      display: none !important;
    }
    .live__chat,
    .live__chat.active,
    .cta__wrapper {
      display: none !important;
      visibility: hidden !important;
    }
    .fullscreen-video {
      .btn-chat,
      .btn-cart {
        display: block !important;
      }
      .cta__wrapper.portraitInactive {
        .cta__nocta {
          visibility: hidden;
          opacity: 0;
        }
      }
      .live__chat.active,
      .cta__wrapper {
        display: block !important;
        visibility: visible !important;
      }
      .vjs-progress-control {
        bottom: 50px;
      }
      .video-js .vjs-remaining-time.vjs-time-control {
        bottom: -6px;
        z-index: 1;
      }
      .live__chat__header.pin-chat {
        position: fixed;
        top: 60px;
        width: 239px !important;
        left: 6px;
        background: #777 !important;
        z-index: 1;
      }
    }
  }
}
