.eventscard {
  position: relative;
  transition: 0.5s ease-in-out;
  margin-right: 24px;
  outline: 0;
  box-shadow: none;
  width: 23.65%;
  margin-bottom: 20px;
  &:nth-child(4n) {
    margin-right: 0px;
  }
  &:last-child {
    margin-right: 0px;
  }
  &__wrap {
    background: #ffffff;
    padding: 6px;
    border-radius: 10px;
    filter: drop-shadow(2px 2px 15px rgba(0, 0, 0, 0.102));
    position: relative;
  }
  &__img {
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    img {
      // padding-top: 10px;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: 100%;
      object-fit: cover;
      transition: all 0.5s;
      transform: translateY(0) scale(1);
      position: absolute;
      bottom: 0;
    }
  }
  &__desc {
    padding: 8px 4px 4px;
    h5 {
      font-size: 16px;
      line-height: 140%;
      color: #000000;
      font-weight: 700;
      padding-right: 10px;
      width: 80%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 5px;
    }
    p {
      font-size: 14px;
      line-height: 140%;
      color: #000000;
      width: 80%;
      padding-right: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 5px;
    }
  }
  &__time {
    display: flex;
    align-items: center;
    margin: 2px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #000000;
    min-height: 30px;
    img {
      margin: 0 10px;
      width: 22px;
    }
  }
  &__timer {
    background: #e0a435;
    color: #fff;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 700;
    cursor: default;
  }
  &__status {
    right: 8px;
    top: 8px;
    background: #ff2525;
    position: absolute;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #ffffff;
    padding: 0px 10px;
    text-transform: capitalize;
  }
  button {
    width: 100%;
    height: 45px;
    opacity: 0;
    transition: 0.3s ease-in-out;
    visibility: hidden;
    transform: translateY(-15px);
  }
  // &:hover {
  //   z-index: 1;
  //   transform: scale(1.03);
  //   .eventscard__img {
  //     img {
  //       transform: scale(1.06);
  //     }
  //   }
  //   button {
  //     opacity: 1;
  //     visibility: visible;
  //     transform: translateY(0px);
  //   }
  // }
  @media screen and (min-width: 1500px) {
    flex: 0 0 auto;
    &__img {
      height: 180px;
    }
  }
  @media screen and (max-width: 991px) {
    width: 47%;
    margin: 8px 0;
    margin-right: 16px;
    &:nth-child(even) {
      margin-right: 0;
    }
    &:nth-child(odd) {
      margin-left: 0;
    }
    &__desc {
      h5 {
        font-size: 14px;
        line-height: 20px;
        width: 100%;
      }
      p {
        width: 100%;
      }
      span {
        font-size: 12px;
        img {
          margin-right: 12px;
          width: 16px;
        }
      }
    }
    button {
      position: relative;
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
    &.eventscard__wrap:hover {
      transform: scale(1);
      .eventscard__img {
        img {
          transform: translateY(0%) scale(1);
        }
      }
      button {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }
  @media screen and (max-width: 520px) {
    .eventscard__img {
      height: 110px;
    }
    &__time {
      min-height: 40px;
      font-size: 10px;
      img {
        width: 15px;
        margin: 0 5px 0 0;
      }
    }
  }
}
